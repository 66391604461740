import React from 'react';
import {
  Redirect
} from "react-router-dom";

class RequestBid extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        redirect:false,
    }
  }

  componentDidMount = () => {
    console.log("**********")
    console.log(this.props.project)
    console.log("**********")
}

updateProject = () => {

}

createProject = () => {

}

  submitBid = () => {
      if(this.props.project.id > 0)
      {
        this.updateProject();
      }else{
          this.createProject();
      }
      this.setState({
          redirect:true
      })
  }

  render() {
    if(this.state.redirect)
    {
        return (<Redirect to="/clientprojects"></Redirect>);
    }

      const multiplesInDiv = (
        <div class="signin-block">
        <div>
            <img class="header-img" src="./../BurgerTechnologies-logo-white.png" />    
        </div>
            <h1>Request Bid</h1>
            <input value={this.props.project.title}/>
            <textarea value={this.props.project.description}></textarea>
            <button onClick={this.submitBid}>Submit For Bid</button>
        </div>
        );

        return multiplesInDiv;
    }
}
  
export default RequestBid;