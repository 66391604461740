import React from 'react';

class NodeLinking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            linecolor:"black",
            links:[
                {
                    startimg:{x:50,y:100},
                    endimg:{x:475,y:250},
                },
                {
                    startimg:{x:200,y:25},
                    endimg:{x:200,y:200},
                }
            ],
            nodes:[
                {
                    width:"100px",
                    height:"100px",
                    x:"0",
                    y:"150px"
                },
                {
                    width:"100px",
                    height:"100px",
                    x:"450px",
                    y:"300px"
                },
                {
                    width:"100px",
                    height:"100px",
                    x:"150px",
                    y:"50px"
                },
                {
                    width:"100px",
                    height:"100px",
                    x:"150px",
                    y:"250px"
                }
            ]
        }
    }
    render() {
        const multiplesInDiv = (
            <div>
                {
                    this.state.nodes.map(node => {
                    return(
                        <div
                            style={{width:node.width, height:node.height, top:node.y, left:node.x, background:"#770", position:"absolute"}} >
                        </div>
                    )
                    })
                }
                <div
                    id="div1"
                    style={{width:"100px", height:"100px", top:"150px", left:"0", background:"#770", position:"absolute"}} >
                </div>
                <div
                    id="div2"
                    style={{width:"100px", height:"100px", top:"300px", left:"450px", background:"#456", position:"absolute"}} >
                </div>
                <div
                    id="div3"
                    style={{width:"100px", height:"100px", top:"50px", left:"150px", background:"#070", position:"absolute"}} >
                </div>
                <div
                    id="div4"
                    style={{width:"100px", height:"100px", top:"250px", left:"150px", background:"#406", position:"absolute"}} >
                </div>
                <svg
                    width="500"
                    height="500">
                    {this.state.links.map(img =>{
                    return(
                        <line
                            x1={img.startimg.x}
                            y1={img.startimg.y}
                            x2={img.endimg.x}
                            y2={img.endimg.y}
                            stroke="black" />
                    );
                    })}
                </svg>
            </div>
        );

        return multiplesInDiv;
    }
}

export default NodeLinking;