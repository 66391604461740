import React from 'react';
import { Redirect } from 'react-router-dom';

class UpdateAccount extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        redirect:false
    }
  }

  gotoProjects = () => {
      this.setState({
          redirect:true
      })
  }

  render() {
      if(this.state.redirect)
      {
          return (<Redirect to={"/clientprojects"} />)
      }
      const multiplesInDiv = (
        <div className="signin-block">
            <h1>Update Account</h1>
            <input placeholder="name" />
            <input placeholder="email" />
            <input placeholder="phone" />
            <input placeholder="password" />
            <input placeholder="passwordver" />
            {/* <PaymentOption></PaymentOption> */}
            <button onClick={this.gotoProjects}>Confirm</button>
        </div>
        );

        return multiplesInDiv;
    }
}
  
export default UpdateAccount;