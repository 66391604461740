import React from 'react';
import {
  Redirect
} from "react-router-dom";

class SignUp extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        redirect:false,
    }
  }

  componentDidMount = () => {
        window.history.pushState("", "", '/signup');
  }

  signup = () => {
    let email = document.getElementById("email").value;
    let pass = document.getElementById("password").value;

    // fetch signup
    this.setState({
        redirect:true
    })
  }

  checkpass = () => {  
    let pass = document.getElementById("password").value;
    let passver = document.getElementById("passwordver").value;
    if(pass !== passver)
    {
        document.getElementById('passVerLabel').style.display = "block";
    }else{
        document.getElementById('passVerLabel').style.display = "none";
    }
  }

  render() {
      if(this.state.redirect)
      {
          return (<Redirect to={"/clientprojects"} />);
      }
      const multiplesInDiv = (
        // <div>
        //     <table>
        //         <thead></thead>
        //         <tbody>
        //             <tr><td><h1>Sign Up</h1></td></tr>
        //             <tr><td><label htmlFor="email">Email</label></td></tr>
        //             <tr><td><input id="email" placeholder="JohnDoe@example.com" /></td></tr>
        //             <tr><td><p id="passVerLabel" style={{color:"red",border:"solid red",display:"none"}}>Passwords Do Not Match</p></td></tr>
        //             <tr><td><label htmlFor="password">Password</label></td></tr>
        //             <tr><td><input id="password" placeholder="********" onKeyUp={this.checkpass}/></td></tr>
        //             <tr><td><label htmlFor="passwordver">Verify Password</label></td></tr>
        //             <tr><td><input id="passwordver" placeholder="********" onKeyUp={this.checkpass}/></td></tr>
        //             <tr><td><button onClick={this.signup}>Sign Up</button></td></tr>
        //         </tbody>
        //         <tfoot></tfoot>
        //     </table>
        // </div>
    <div class="signin-block">
        <div>
            <img class="header-img" src="./../BurgerTechnologies-logo-white.png" />    
        </div>
        <label htmlFor="email">Email</label>
        <input id="email" placeholder="Email@example.com" />
        <p id="passVerLabel" style={{color:"red",border:"solid red",display:"none"}}>Passwords Do Not Match</p>
        <label htmlFor="password">Password</label>
        <input id="password" type="password" placeholder="********" onKeyUp={this.checkpass}/>
        <label htmlFor="passwordver">Verify Password</label>
        <input id="passwordver" type="password" placeholder="********" onKeyUp={this.checkpass}/>
        <button onClick={this.signup}>Sign Up</button>
    </div>
        );

        return multiplesInDiv;
    }
}
  
export default SignUp;