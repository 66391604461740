import React from 'react';
import {
  Redirect
} from "react-router-dom";

class SignIn extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        redirect:false
    }
  }


  signin = () => {
    let email = document.getElementById("email").value;
    let pass = document.getElementById("password").value;
    /**fetch login */
    this.setState({
        redirect:true
    })
    
  }

  render() {
    if(this.state.redirect)
    {
        return (<Redirect to={"/clientprojects"} />);
    }
      const multiplesInDiv = (
        // <div>
        //     <table>
        //         <thead></thead>
        //         <tbody>
        //             <tr><td><h1>Sign In</h1></td></tr>
        //             <tr><td><label htmlFor="email">Email:</label></td></tr>
        //             <tr><td><input id="email" placeholder="JohnDoe@example.com" /></td></tr>
        //             <tr><td><label htmlFor="password">Password:</label></td></tr>
        //             <tr><td><input id="password" placeholder="********" /></td></tr>
        //             <tr><td><button onClick={this.signin}>Sign In</button></td></tr>
        //         </tbody>
        //         <tfoot></tfoot>
        //     </table>
        // </div>
        
    <div class="signin-block">
        <div>
            <img class="header-img" src="./BurgerTechnologies-logo-white.png" />    
        </div>
        <label htmlFor="email">Email:</label>
        <input id="email" placeholder="Email@example.com" />
        <label htmlFor="password">Password:</label>
        <input id="password" type="password" placeholder="********" />
        <button onClick={this.signin}>Sign In</button>
    </div>
        );

        return multiplesInDiv;
    }
}
  
export default SignIn;