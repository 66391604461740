import React from 'react';
import { Redirect } from 'react-router';

class ClientProjects extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        redirectRequestBid:false,
        redirectPay:false,
        redirectUserUpdate:false,
        projects:[]
    }
  }

  componentDidMount = () => {
      this.setState({
          projects: this.props.projects
      })
  }

  gotoBidTwo = () => {
    this.setState({
        redirectRequestBid:true,
    },() => {
        this.props.setProject(0);
    })
}
gotoBid = () => {
  this.setState({
      redirectRequestBid:true,
  })
}
gotoPay = () => {
    this.setState({
        redirectPay:true,
    })
}
gotoUserUpdate = () => {
    this.setState({
        redirectUserUpdate:true,
    })
}

  render() {
    if(this.state.redirectRequestBid)
    {
        return (<Redirect to={"/requestbid"} />);
    }
    
    if(this.state.redirectPay)
    {
        return (<Redirect to={"/pay"} />);
    }
    
    if(this.state.redirectUserUpdate)
    {
        return (<Redirect to={"/updateaccount"} />);
    }
      const multiplesInDiv = (
        <div>
            <ProjectMenu gotoPay={this.gotoPay} gotoUserUpdate={this.gotoUserUpdate}></ProjectMenu>
            <h1>Your Projects</h1>
            {this.props.projects.map((project) => {
                return(<ProjectTab project={project} setProject={this.props.setProject} gotoBid={this.gotoBid}></ProjectTab>)
            })}
            <button onClick={this.gotoBidTwo}>Add</button>
        </div>
        );

        return multiplesInDiv;
    }
}

class ProjectTab extends React.Component {
    constructor(props){
      super(props);
      this.state = {
          redirectRequestBid:false,
          redirectPay:false,
          redirectUserUpdate:false,
          project:this.props.project
      }
    }

    componentDidMount = () => {
        console.log("**********")
        console.log(this.props.project)
        console.log("**********")
    }

    selectProject = () => {
        this.props.setProject(this.state.project);
        this.props.gotoBid();
    }

    render() {
        const multiplesInDiv = (
          <div className="projectcard" onClick={this.selectProject}>
              <p>{this.props.project.id}</p>
              <p>{this.props.project.title}</p>
              <p>{this.props.project.description}</p>
          </div>
          );
        return multiplesInDiv;
    }
}

class ProjectMenu extends React.Component {
    constructor(props){
      super(props);
      this.state = {
          className:"hiddenMenu"
      }
    }

    showMenu = () => {
        if(this.state.className==="hiddenMenu")
        {
            this.setState({
                className:"visibleMenu"
            })
        }else{
            this.setState({
                className:"hiddenMenu"
            })
        }
    }

    render() {
        const multiplesInDiv = (
        <div>
            <button onClick={this.showMenu}>Show Menu</button>
            <div className={this.state.className}>
                <h1>Menu</h1>
                <button onClick={this.props.gotoPay}>Pay</button>
                <button onClick={this.props.gotoUserUpdate}>Update</button>
            </div>
          </div>
          );
        return multiplesInDiv;
    }
}



  
export default ClientProjects;