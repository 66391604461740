import React from 'react';
import ViewRequests from './viewrequests';

class ClientsOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [
                {
                    id: 1,
                    name: "One",
                    requests: 5,
                    projects: [
                        {
                            id: 1,
                            name: "pOne",
                            requests: 3,
                            gitlink:"bigLongGItString",
                        },
                        {
                            id: 2,
                            name: "pTwo",
                            requests: 1,
                            gitlink:"bigLongGItString",
                        },
                    ],
                },
                {
                    id: 2,
                    name: "Two",
                    requests: 9,
                    projects: [
                        {
                            id: 1,
                            name: "pOne2",
                            requests: 6,
                            gitlink:"bigLongGItString",
                        },
                        {
                            id: 2,
                            name: "pTwo2",
                            requests: 2,
                            gitlink:"bigLongGItString",
                        },
                        {
                            id: 3,
                            name: "pTwo2",
                            requests: 1,
                            gitlink:"bigLongGItString",
                        },
                    ],
                },
                {
                    id: 3,
                    name: "Three",
                    requests: 2,
                    projects: [
                        {
                            id: 1,
                            name: "pOne3",
                            requests: 2,
                            gitlink:"bigLongGItString",
                        },
                    ],
                },
            ],
        }
    }
    render() {
        const multiplesInDiv = (
            <div>
                <h1>Client Overview</h1>
                <table>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>Client</th>
                            <th>Requests</th>
                            <th>Projects</th>
                        </tr>
                        {
                            this.state.clients.map(clnt => {
                                return(
                                    <tr key={Math.random(50000)}>
                                        <td>{clnt.name}</td>
                                        <td>{clnt.requests}</td>
                                        <td>{clnt.projects.length}</td>
                                        <td><ViewRequests projects={clnt.projects} /></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        );

        return multiplesInDiv;
    }
}

export default ClientsOverview;