import React from 'react';
import {Redirect} from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement} from '@stripe/react-stripe-js';

class PayMe extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        redirect:false
    }
  }

  gotoProjects = () => {
      this.setState({
          redirect:true,
      })
  }

  render() {
    if(this.state.redirect)
    {
        return (<Redirect to="/clientprojects"></Redirect>);
    }
      const multiplesInDiv = (
        <div className="signin-block">
            <h1>Pay Your Bill</h1>
            <input placeholder="Card spot from stripe" />
            <button onClick={this.gotoProjects}>Confirm</button>
        </div>
        );

        return multiplesInDiv;
    }
}
  
export default PayMe;