import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
} from "react-router-dom";
import ReactLoading from 'react-loading';
import './index.css';
import reportWebVitals from './reportWebVitals';
import SignUp from './signup';
import SignIn from './signin';
import AdminDash from './admindash';
import NodeLinking from './nodelinking';
import UpdateAccount from './updateaccount';
import RequestBid from './requestbid';
import PayMe from './payme';
import ClientProjects from './clientprojects';
import SignUpHardMode from './signuphard';

class MainPage extends React.Component {
    constructor() {
        super();
        this.state = {
            project:{},
            projects:[
                {id:1,title:"name1",description:"morestuffdesc0"},
                {id:2,title:"name2",description:"morestuffdesc1"},
                {id:3,title:"name3",description:"morestuffdesc2"},
                {id:4,title:"name4",description:"morestuffdesc3"},
                {id:5,title:"name5",description:"morestuffdesc4"},
            ],
            signedin:false,
        }
    }

    signin = () => {

    }

    signout = () => {

    }

    toggleNav = () => {
        if(this.state.navToggle)
        {
            this.setState({
                navToggle: false,
            },() => {document.getElementById("navList").style.display = "none"})
        }else{
            this.setState({
                navToggle: true,
            },() => {document.getElementById("navList").style.display = "block"})
        }
    }

    setProject = project => {
        console.log(project);
        let projectOut = project;
        // let projectOut = this.state.projects[0];
        // this.state.projects.map(element => {
        //     if(element.id === projectid)
        //     {
        //         projectOut = element;
        //     }
        // });
        this.setState({
            project:projectOut,
        })
    }


    render() {
        let navclass = "hiddenNav";
        if(this.state.signedin)
        {
            navclass = "showNav";
        }
        return (
            <div>
                <Router>
                    <div>
                        <nav>
                            <div className="hamburger" onClick={this.toggleNav}>
                                <div className="topbun"></div>
                                <div className="patty"></div>
                                <div className="bottombun"></div>
                            </div>
                            <ul className={navclass} id="navList">
                                <div className="tooltip">
                                <li>
                                    <Link to="/signin">
                                        <img src="./eject.png" alt="Logout Button" />
                                    </Link>
                                </li>
                                <span class="tooltiptext">Logout</span>
                                </div>
                            </ul>
                        </nav>
                        <Switch>
                            <Route exact path="/">
                                <SignIn signout={this.signout} signin={this.signin} />
                            </Route>
                            <Route path="/signin">
                                <SignIn signout={this.signout} signin={this.signin} />
                            </Route>
                            <Route path="/signup/official">
                                <SignUp />
                            </Route>
                            <Route path="/signup">
                                <SignUpHardMode></SignUpHardMode>
                            </Route>
                            <Route path="/admin">
                                <AdminDash />
                            </Route>
                            <Route path="/nodelinking">
                                <NodeLinking />
                            </Route>
                            <Route path="/clientprojects">
                                <ClientProjects projects={this.state.projects} setProject={this.setProject}></ClientProjects>
                            </Route>
                            <Route path="/requestbid">
                                <RequestBid project={this.state.project}></RequestBid>
                            </Route>
                            <Route path="/updateaccount">
                                <UpdateAccount></UpdateAccount>
                            </Route>
                            <Route path="/pay">
                                <PayMe></PayMe>
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </div>
        );
    }
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <MainPage></MainPage>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
