import React from 'react';

class ViewRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const multiplesInDiv = (
            <div>
                <table>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Requests</th>
                            <th>Git Link</th>
                        </tr>
                        {
                            this.props.projects.map(project => {
                                return (
                                    <tr key={Math.random(50000)}>
                                        <td>{project.id}</td>
                                        <td>{project.name}</td>
                                        <td>{project.requests}</td>
                                        <td><a>{project.gitlink}</a></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        );

        return multiplesInDiv;
    }
}

export default ViewRequests;