import React from 'react';
import ClientsOverview from './clientsoverview';

class AdminDash extends React.Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }
  render() {
      const multiplesInDiv = (
        <div>
            <h1>Admin Dashboard</h1>
            <ClientsOverview />
        </div>
        );

        return multiplesInDiv;
    }
}
  
export default AdminDash;